// @flow
import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
import layoutSaga from "./layout/saga";
import businessEmailSaga from "./auth/businessEmail/saga";
import otpSendEmailSaga from "./auth/otpSendEmail/saga";
import verifyDetailSaga from "./auth/verifyDetail/saga";
import ProfileSaga from "../redux/accountPro/profile/saga";
import resetPasswordSaga from "./auth/resetPassword/saga";
import organisationSaga from "./accountPro/organisation/saga";
import subUserSaga from "./subUser/saga";
import authComponentDataSendSaga from "./ComponentDataSend/authComponentDataSend/saga";
import allAnnouncementSaga from "./accountPro/Announcements/saga";
import getVatDetailsSaga from "./accountPro/vatDetails/saga";
import accountantSearchAllSaga from "./accountPro/SearchAccountant/saga";
import AllRatingSaga from "./accountPro/AddRating/saga";
import AllNotifectionSaga from "./accountPro/Notifection/saga";
import AllChargesSaga from "./accountPro/Charges/saga";

export default function* rootSaga(): any {
  yield all([
    authSaga(),
    layoutSaga(),
    businessEmailSaga(),
    otpSendEmailSaga(),
    verifyDetailSaga(),
    ProfileSaga(),
    resetPasswordSaga(),
    organisationSaga(),
    subUserSaga(),
    authComponentDataSendSaga(),
    allAnnouncementSaga(),
    getVatDetailsSaga(),
    accountantSearchAllSaga(),
    AllRatingSaga(),
    AllNotifectionSaga(),
    AllChargesSaga(),
  ]);
}
