import { Charges } from "./constant";

const Initial_State = {
  data: [],
  loading: false,
  message: "",
};

export const getChargesReducer = (state = Initial_State, action) => {
  switch (action.type) {
    case Charges.GET_CHARGES_LOADING:
      return {
        data: state.data,
        loading: true,
      };
    case Charges.GET_CHARGES_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case Charges.GET_CHARGES_ERROR:
      return {
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
