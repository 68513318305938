import { VatDetailsActionTypes } from "./constant";

const GET_VAT_DETAILS_INITIAL_STATE = {
    getVatDetails: [],
    loading: false,
    message: "",
};
const initial_state = {
    data: [],
    message: "",
    loading: false,
};

const getVatDetailsReducer = (
    state = GET_VAT_DETAILS_INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case VatDetailsActionTypes.GET_VAT_DETAILS_LOADING:
            return {
                getVatDetails: state.getVatDetails,
                loading: true,
            };
        case VatDetailsActionTypes.GET_VAT_DETAILS_SUCCESS:
            return {
                getVatDetails: action.payload,
                loading: false,
            };
        case VatDetailsActionTypes.GET_VAT_DETAILS_ERROR:
            return {
                getVatDetails: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

const approveProvisionalReducers = (state = initial_state, action) => {
    switch (action.type) {
        case VatDetailsActionTypes.APPROVE_PROVISIONAL_UPDATE_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case VatDetailsActionTypes.APPROVE_PROVISIONAL_UPDATE_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case VatDetailsActionTypes.APPROVE_PROVISIONAL_UPDATE_RESET:
            return {
                data: action?.payload,
                loading: false,
            };
        case VatDetailsActionTypes.APPROVE_PROVISIONAL_UPDATE_ERROR:
            return {
                data: state,
                loading: false,
            };
        default:
            return { ...state };
    }
};

const rejectProvisionalReducers = (state = initial_state, action) => {
    switch (action.type) {
        case VatDetailsActionTypes.REJECT_PROVISIONAL_UPDATE_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case VatDetailsActionTypes.REJECT_PROVISIONAL_UPDATE_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case VatDetailsActionTypes.REJECT_PROVISIONAL_UPDATE_RESET:
            return {
                data: action?.payload,
                loading: false,
            };
        case VatDetailsActionTypes.REJECT_PROVISIONAL_UPDATE_ERROR:
            return {
                data: state,
                loading: false,
            };
        default:
            return { ...state };
    }
};

export {
    getVatDetailsReducer,
    approveProvisionalReducers,
    rejectProvisionalReducers
};
