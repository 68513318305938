import React, { Suspense, useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";
import * as layoutConstants from "../constants/layout";

// All layouts/containers
import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal";
import FullLayout from "../layouts/Full";
import ErrorPageNotFound from "../helpers/pageNotFound/ErrorPageNotFound";
// lazy load all the views

// auth
// new code 13-12-2023
const Logout = React.lazy(() => import("../pages/account/Logout"));
const AuthIndex = React.lazy(() => import("../auth/AuthIndex"));
const SignupBusinessForm = React.lazy(() =>
  import("../auth/signup/signupBusinessEmail/SignupBusinessEmailForm")
);
const SignupVerifyDetailForm = React.lazy(() =>
  import("../auth/signup/signupVerifyDetail/SignupVerifyDetailForm")
);
const SignupCreatePasswordForm = React.lazy(() =>
  import("../auth/signup/signupCreatePassword/SignupCreatePasswordForm")
);
const SignupOtpSendEmailForm = React.lazy(() =>
  import("../auth/signup/signupOtpSendEmail/SignupOtpSendEmailForm")
);
const WelcomeAccount = React.lazy(() =>
  import("../auth/Welcome/WelcomeAccount")
);
const ForgetPasswordEmail = React.lazy(() =>
  import("../auth/forgetPassword/ForgetPasswordEmail")
);
const ForgetPasswordVerify = React.lazy(() =>
  import("../auth/forgetPassword/ForgetPasswordVerify")
);
const SubClientDeactiveModel = React.lazy(() =>
  import("../auth/logout/SubClientDeactiveModel")
);

const ClientDashboard = React.lazy(() =>
  import("../pages/accountpro/clientDashboard/DashboardIndex")
);
const NorthDataGraph = React.lazy(() =>
  import("../pages/graphdata/GraphNorthData")
);
// new code 13-12-2023
// auth

// // dashboard
const EcommerceDashboard = React.lazy(() =>
  import("../pages/dashboard/Ecommerce")
);
//const Myaccount = React.lazy(() => import("../pages/accountpro/accountpro"));
const OrganisationIndex = React.lazy(() =>
  import("../pages/accountpro/organisation/OrganisationIndex")
);
const SubUser = React.lazy(() =>
  import("../pages/accountpro/subUser/SubUserIndex")
);
const Myaccount = React.lazy(() =>
  import("../pages/accountpro/profile/profileIndex")
);
const MyAccountant = React.lazy(() =>
  import("../pages/accountpro/Accountant/MyAccountant")
);
const AccountantSearchMain = React.lazy(() =>
  import("../pages/accountpro/Accountant/AccountantSearchMain")
);
const SearchAccountants = React.lazy(() =>
  import("../pages/accountpro/Accountant/SearchAccountant")
);
const InviteAccountant = React.lazy(() =>
  import("../pages/accountpro/Accountant/InviteAccountant")
);
const AccountantValidator = React.lazy(() =>
  import("../pages/accountpro/Accountant/AccountValidation")
);
const Contract = React.lazy(() =>
  import("../pages/accountpro/Accountant/Contract")
);
const MyAccount = React.lazy(() =>
  import("../pages/accountpro/Accountant/MyAccount")
);

const AccessDeniedPage = React.lazy(() =>
  import("../auth/accessDenied/AccessDeniedPage")
);
const OfflinePage = React.lazy(() =>
  import("../pages/accountpro/OfflinePage")
);
// // dashboard

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  const appearance = {
    theme: "stripe",
  };

  const store = useSelector((state) => state);
  const authCheck = store?.Auth?.user?.data?.roleId?.role;
  const { layout } = useSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (layout.layoutType) {
      case layoutConstants.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case layoutConstants.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case layoutConstants.LAYOUT_FULL:
        layoutCls = FullLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();

  const authorizationRole = useSelector((state) => state?.Auth?.user?.role);

  return useRoutes([
    { path: "/", element: <Root /> },
    {
      path: "/",
      element: <DefaultLayout />,
      children: [
        {
          path: "client",
          children: [
            { path: "login", element: <LoadComponent component={AuthIndex} /> },
            {
              path: "signup",
              element: <LoadComponent component={AuthIndex} />,
            },
            {
              path: "offlinePage",
              element: <LoadComponent component={OfflinePage} />,
            },
            {
              path: "signupVerify",
              element: <LoadComponent component={AuthIndex} />,
            },
            {
              path: "signupCreatePassword",
              element: <LoadComponent component={SignupCreatePasswordForm} />,
            },
            {
              path: "signupOtpSend/:id/:token",
              element: <LoadComponent component={AuthIndex} />,
            },
            {
              path: "welcomeAccount",
              element: <LoadComponent component={WelcomeAccount} />,
            },
            {
              path: "forgetPasswordEmail",
              element: <LoadComponent component={ForgetPasswordEmail} />,
            },
            {
              path: "reset-password",
              element: <LoadComponent component={ForgetPasswordVerify} />,
            },
            {
              path: "reset-password/:id",
              element: <LoadComponent component={ForgetPasswordVerify} />,
            },
            { path: "logout", element: <LoadComponent component={Logout} /> },
            {
              path: "accessDeniedPage",
              element: <LoadComponent component={AccessDeniedPage} />,
            },
            {
              path: "accountLock",
              element: <LoadComponent component={SubClientDeactiveModel} />,
            },

            {
              path: "*",
              element: <LoadComponent component={ErrorPageNotFound} />,
            },
          ],
        },
      ],
    },

    {
      // auth protected routes
      path: "/",
      element: <PrivateRoute roles={authorizationRole} component={Layout} />,
      children: [
        {
          path: "dashboard",
          children: [
            {
              path: "ecommerce",
              element: <LoadComponent component={ClientDashboard} />,
            },
            {
              path: "graph",
              element: <LoadComponent component={NorthDataGraph} />,
            },
            {
              path: "account",
              element: <LoadComponent component={Myaccount} />,
            },
            {
              path: "organization",
              element: <LoadComponent component={OrganisationIndex} />,
            },
            {
              path: "accountant",
              element: <LoadComponent component={MyAccountant} />,
            },
            {
              path: "searchaccountant",
              element: <LoadComponent component={SearchAccountants} />,
            },
            {
              path: "inviteaccountant",
              element: <LoadComponent component={InviteAccountant} />,
            },
            {
              path: "accountsearchmain",
              element: <LoadComponent component={AccountantSearchMain} />,
            },
            {
              path: "accountvalidation",
              element: <LoadComponent component={AccountantValidator} />,
            },
            {
              path: "contrcat",
              element: <LoadComponent component={Contract} />,
            },
            {
              path: "myaccount",
              element: <LoadComponent component={MyAccount} />,
            },
            {
              path: "subuser",
              element: <LoadComponent component={SubUser} />,
            },
            {
              path: "*",
              element: <LoadComponent component={ErrorPageNotFound} />,
            },
          ],
        },
      ],
    },
  ]);
};

export { AllRoutes };
