// @flow
import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import { businessEmailReducer } from "./auth/businessEmail/reducers";
import {
  otpSendEmailReducer,
  subUserSendEmailVerifyReducer,
} from "./auth/otpSendEmail/reducers";
import {
  getCompanyNameReducer,
  registerUserReducer,
  companyCheckVerifyReducer,
  companyCreateReducer,
} from "./auth/verifyDetail/reducers";
import {
  resetPasswordEmailReducer,
  resetPasswordVerifyReducer,
} from "./auth/resetPassword/reducers";
import {
  organisationSettingReducer,
  getOrganisationSettingReducer,
  emailSendVerifyReducer,
  deleteEmailSendVerifyReducer,
  otpSendVerifyReducer,
} from "./accountPro/organisation/reducer";
import {
  updateProfileReducer,
  getProfileReducer,
  updatePasswordReducer,
  accountDetailReducer,
  mobileNumberVerifyReducer,
  mobileNumberVerifyOtpSendReducer,
} from "../redux/accountPro/profile/reducer";
import {
  getSubUserReducer,
  addSubUserReducer,
  updateSubUserReducer,
  deleteSubClientReducer,
} from "./subUser/reducer";
import {
  otpTimeStartReducer,
  signupVerifyBackBtnTimeStopReducer,
  mbleSccrnSideBrClseReducer,
} from "./ComponentDataSend/authComponentDataSend/reducers";
import { getAnnouncementsReducer } from "./accountPro/Announcements/reducer";
import { getVatDetailsReducer, approveProvisionalReducers, rejectProvisionalReducers } from "./accountPro/vatDetails/reducer";
import {
  addRatingReducer,
  getRatingReducer,
} from "./accountPro/AddRating/reducer";
import {
  getNotifectionReducer,
  getNotifectionQountReducer,
} from "./accountPro/Notifection/reducer";

import {
  getIndustoryReducer,
  sendInvitationReducer,
  getCalendlyTokenReducers,
  searchAccountantReducer,
  getSentInvitationReducers,
  sendAccountValidateStripeReducer,
  getAccountantDetailsReducers,
  getClientAccountantDetailsReducers,
  updatePaymentMethodReducers,
  getDocusignTokenReducers,
  generateContractReducer,
  getContractListReducer,
  getClientContractContractReducer,
  getAmountReducer,
} from "./accountPro/SearchAccountant/reducer";
import { getChargesReducer } from "./accountPro/Charges/reducer";

export default (combineReducers({
  Auth,
  Layout,
  businessEmailReducer,
  otpSendEmailReducer,
  subUserSendEmailVerifyReducer,
  getCompanyNameReducer,
  registerUserReducer,
  companyCheckVerifyReducer,
  resetPasswordEmailReducer,
  resetPasswordVerifyReducer,
  updateProfileReducer,
  getProfileReducer,
  organisationSettingReducer,
  getOrganisationSettingReducer,
  emailSendVerifyReducer,
  otpSendVerifyReducer,
  updatePasswordReducer,
  getSubUserReducer,
  addSubUserReducer,
  updateSubUserReducer,
  deleteSubClientReducer,
  otpTimeStartReducer,
  signupVerifyBackBtnTimeStopReducer,
  companyCreateReducer,
  accountDetailReducer,
  mobileNumberVerifyReducer,
  mobileNumberVerifyOtpSendReducer,
  deleteEmailSendVerifyReducer,
  mbleSccrnSideBrClseReducer,
  getAnnouncementsReducer,
  getVatDetailsReducer,
  approveProvisionalReducers,
  rejectProvisionalReducers,
  searchAccountantReducer,
  getIndustoryReducer,
  sendInvitationReducer,
  getCalendlyTokenReducers,
  getSentInvitationReducers,
  sendAccountValidateStripeReducer,
  getAccountantDetailsReducers,
  getClientAccountantDetailsReducers,
  updatePaymentMethodReducers,
  getDocusignTokenReducers,
  generateContractReducer,
  getContractListReducer,
  getClientContractContractReducer,
  addRatingReducer,
  getRatingReducer,
  getNotifectionReducer,
  getNotifectionQountReducer,
  getAmountReducer,
  getChargesReducer,
}): any);
