import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { NotifectionTypes } from "./constant";
import { getNotifectionEndPoint, getQountNotifectionEndPoint } from "./api";

function* getNotifectionFunction(data) {
    try {
        yield put({
            type: NotifectionTypes.GET_NOTIFECTION_LOADING,
            payload: {},
        });
        const response = yield call(getNotifectionEndPoint, data);
        if (response.data.status) {
            yield put({
                type: NotifectionTypes.GET_NOTIFECTION_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: NotifectionTypes.GET_NOTIFECTION_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: NotifectionTypes.GET_NOTIFECTION_RESET,
            payload: error?.data,
        });
        // yield put({
        //     type: RatingTypes.GET_RATING_RESET,
        //     payload: {},
        // });
    }
}

function* getNotifectionQountFunction(data) {
    try {
        yield put({
            type: NotifectionTypes.GET_NOTIFECTION_QOUNT_LOADING,
            payload: {},
        });
        const response = yield call(getQountNotifectionEndPoint, data);
        if (response.data.status) {
            yield put({
                type: NotifectionTypes.GET_NOTIFECTION_QOUNT_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: NotifectionTypes.GET_NOTIFECTION_QOUNT_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: NotifectionTypes.GET_NOTIFECTION_QOUNT_RESET,
            payload: error?.data,
        });
        // yield put({
        //     type: RatingTypes.GET_RATING_RESET,
        //     payload: {},
        // });
    }
}

export function* acctionGetNotifection(): any {
    yield takeEvery(
        NotifectionTypes.GET_NOTIFECTION_FIRST,
        getNotifectionFunction
    );
}
export function* acctionGetNotifectionQount(): any {
    yield takeEvery(
        NotifectionTypes.GET_NOTIFECTION_QOUNT_FIRST,
        getNotifectionQountFunction
    );
}

function* AllNotifectionSaga(): any {
    yield all([
        // fork(acctionAddRating),
        fork(acctionGetNotifection),
        fork(acctionGetNotifectionQount),
    ]);
}
export default AllNotifectionSaga;
