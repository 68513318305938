import React from "react";

export const ButtonLoading = () => {
  return (
    <>
      <div className="btn_loader ">
        <div class="spinner-border " role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  );
};

export const FormLoading = () => {
  return (
    <div className="spinner-border_form text-primary">
      <div class="spinner-border " role="status">
        <span class="visually-hidden">Loading...</span>
      </div>{" "}
    </div>
  );
};

export const ClientProfileFormLoading = () => {
  return (
    <div className="spinner-border_form1 text-primary">
      <div class="spinner-border " role="status">
        <span class="visually-hidden">Loading...</span>
      </div>{" "}
    </div>
  );
};

export const NorthApiCompanyLoading = () => {
  return (
    <>
      <div className="north_api_comapnyLoader text-primary">
        <div class="spinner-border " role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  );
};

export const EmailVerifyFormLoading = () => {
  return (
    <div className="spinner-border_Verify_form text-primary">
      <div class="spinner-border " role="status">
        <span class="visually-hidden">Loading...</span>
      </div>{" "}
    </div>
  );
};

export const DashboardLoader = () => {
  return (
    <div className="btn_loader d-flex justify-content-center pt-2 my-5 text-primary">
      <div class="spinner-border " role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
