import { VatDetailsActionTypes } from "./constant";

export const getVatDetailsActions = (data): LayoutAction => ({
    type: VatDetailsActionTypes.GET_VAT_DETAILS_FIRST,
    data,
});

export const approveProvisionalAction = (data) => ({
    type: VatDetailsActionTypes.APPROVE_PROVISIONAL_UPDATE_FIRST,
    payload: data,
});
export const rejectProvisionalAction = (data) => ({
    type: VatDetailsActionTypes.REJECT_PROVISIONAL_UPDATE_FIRST,
    payload: data,
});
