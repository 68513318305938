export const VatDetailsActionTypes = {
    GET_VAT_DETAILS_FIRST: "@@/layout/GET_VAT_DETAILS_FIRST",
    GET_VAT_DETAILS_LOADING: "@@/layout/GET_VAT_DETAILS_LOADING",
    GET_VAT_DETAILS_SUCCESS: "@@/layout/GET_VAT_DETAILS_SUCCESS",
    GET_VAT_DETAILS_ERROR: "@@/layout/GET_VAT_DETAILS_ERROR",

    APPROVE_PROVISIONAL_UPDATE_FIRST: "@@/layout/APPROVE_PROVISIONAL_UPDATE_FIRST",
    APPROVE_PROVISIONAL_UPDATE_LOADING: "@@/layout/APPROVE_PROVISIONAL_UPDATE_LOADING",
    APPROVE_PROVISIONAL_UPDATE_SUCCESS: "@@/layout/APPROVE_PROVISIONAL_UPDATE_SUCCESS",
    APPROVE_PROVISIONAL_UPDATE_ERROR: "@@/layout/APPROVE_PROVISIONAL_UPDATE_ERROR",
    APPROVE_PROVISIONAL_UPDATE_RESET: "@@/layout/APPROVE_PROVISIONAL_UPDATE_RESET",

    REJECT_PROVISIONAL_UPDATE_FIRST: "@@/layout/REJECT_PROVISIONAL_UPDATE_FIRST",
    REJECT_PROVISIONAL_UPDATE_LOADING: "@@/layout/REJECT_PROVISIONAL_UPDATE_LOADING",
    REJECT_PROVISIONAL_UPDATE_SUCCESS: "@@/layout/REJECT_PROVISIONAL_UPDATE_SUCCESS",
    REJECT_PROVISIONAL_UPDATE_ERROR: "@@/layout/REJECT_PROVISIONAL_UPDATE_ERROR",
    REJECT_PROVISIONAL_UPDATE_RESET: "@@/layout/REJECT_PROVISIONAL_UPDATE_RESET",
};
