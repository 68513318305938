import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { VatDetailsActionTypes } from "./constant";
import { approveProvisonalApi, getVatDetails, rejectProvisonalApi } from "./api";

function* callGetVatDetails(data) {
    try {
        yield put({
            type: VatDetailsActionTypes.GET_VAT_DETAILS_LOADING,
            payload: {},
        });
        const response = yield call(getVatDetails, data);
        if (response.data.status) {
            yield put({
                type: VatDetailsActionTypes.GET_VAT_DETAILS_SUCCESS,
                payload: { ...response.data },
            });
        } else {
            yield put({
                type: VatDetailsActionTypes.GET_VAT_DETAILS_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: VatDetailsActionTypes.GET_VAT_DETAILS_ERROR,
            payload: error,
        });
    }
}

function* provisionalVatApproveFunction({ payload }) {
    try {
        yield put({
            type: VatDetailsActionTypes.APPROVE_PROVISIONAL_UPDATE_LOADING,
            payload: {},
        });
        const response = yield call(approveProvisonalApi, { payload });
        if (response.data.status) {
            yield put({
                type: VatDetailsActionTypes.APPROVE_PROVISIONAL_UPDATE_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: VatDetailsActionTypes.APPROVE_PROVISIONAL_UPDATE_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: VatDetailsActionTypes.APPROVE_PROVISIONAL_UPDATE_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: VatDetailsActionTypes.APPROVE_PROVISIONAL_UPDATE_ERROR,
            payload: error?.data,
        });
    }
}

function* provisionalVatRejectFunction({ payload }) {
    try {
        yield put({
            type: VatDetailsActionTypes.REJECT_PROVISIONAL_UPDATE_LOADING,
            payload: {},
        });
        const response = yield call(rejectProvisonalApi, { payload });
        if (response.data.status) {
            yield put({
                type: VatDetailsActionTypes.REJECT_PROVISIONAL_UPDATE_SUCCESS,
                payload: { ...response.data },
            });
            yield put({
                type: VatDetailsActionTypes.REJECT_PROVISIONAL_UPDATE_RESET,
                payload: {},
            });
        } else {
            yield put({
                type: VatDetailsActionTypes.REJECT_PROVISIONAL_UPDATE_ERROR,
                payload: { ...response.data },
            });
        }
    } catch (error) {
        yield put({
            type: VatDetailsActionTypes.REJECT_PROVISIONAL_UPDATE_ERROR,
            payload: error?.data,
        });
    }
}

export function* getVatDetailsAction(): any {
    yield takeEvery(
        VatDetailsActionTypes.GET_VAT_DETAILS_FIRST,
        callGetVatDetails
    );
}
export function* approveProvisionalVatAction(): any {
    yield takeEvery(
        VatDetailsActionTypes.APPROVE_PROVISIONAL_UPDATE_FIRST,
        provisionalVatApproveFunction
    );
}
export function* rejectProvisionalVatAction(): any {
    yield takeEvery(
        VatDetailsActionTypes.REJECT_PROVISIONAL_UPDATE_FIRST,
        provisionalVatRejectFunction
    );
}

function* getVatDetailsSaga(): any {
    yield all([
        fork(getVatDetailsAction),
        fork(approveProvisionalVatAction),
        fork(rejectProvisionalVatAction),
    ]);
}
export default getVatDetailsSaga;
