import * as URL from "../../../helpers/api/apiEndPoint";
import { APICore } from "../../../helpers/api/apiCore";
const api = new APICore();

export function searchAccountantApi(data): any {
  return api.update(`${URL.SEARCH_ACCOUNTANT}`, data?.payload);
}

export function getIndustoryEndPoint(page, limit) {
  return api.get(`${URL.GET_INDUSTORY}page=${1}&limit=${30}`);
}

export function getCalendlyTokenEndPoint(data: any) {
  // return api.create(URL.GET_CALENDLY_APITOKEN);
  return api.create(`${URL.GET_CALENDLY_APITOKEN}`, data?.payload);
}

export function sendInvitationApi(data) {
  return api.create(`${URL.SEND_INVITATION_TO_ACCOUNTANT}`, data?.payload);
}

export function getSentInvitationApi(data) {
  return api.get(URL.GET_SEND_INVITE_ACCOUNTANT_LIST);
}

export function postAccountStripeApi(data) {
  return api.update(`${URL.POST_ACCOUNT_VALIDATE_STRIPE_SEPA}`, data?.payload);
}

export function getAccountantDetailsApi(params: any) {
  return api.get(URL.GET_ACCOUNTANT_DETAILS);
}

export function getClientAccountantDetailsApi(params: any) {
  return api.get(URL.GET_CLIENT_ACCOUNTANT_DETAILS);
}
export function updatePaymentMethodApi(data) {
  return api.update(URL.UPDATE_PAYMENT_METHOD, data?.payload);
}

export function generateContractApi(data) {
  return api.create(URL.GENERATE_CONTRACT, data?.payload);
}

export function getDocusignTokenEndPoint(data: any) {
  // return api.create(URL.GET_CALENDLY_APITOKEN);
  return api.get(URL.GET_DOCUSIGN_APITOKEN);
}

export function getAmountEndPoint(data: any) {
  // return api.create(URL.GET_CALENDLY_APITOKEN);
  return api.get(URL.GET_AMOUNT);
}

export function getContractListEndPoint(accessTokenSend) {
  let url = URL.GET_CONTRACT;
  const token = localStorage?.getItem("token");
  // Prepare the query parameters with the token
  const params = {
    accessToken: token, // Send the token as a query parameter
  };

  // Correct the return statement in the ternary operator
  return accessTokenSend ? api.get(url, params) : api.get(url);
}

export function getClientContractListEndPoint(subscriptionType: string) {

  return api.get(`${URL.GET_AMOUNT}?subscriptionType=${subscriptionType}`);
}
