import { APICore } from "../../../helpers/api/apiCore";
import * as URL from "../../../helpers/api/apiEndPoint";
const api = new APICore();

function getVatDetails(params: any): any {
    const { year } = params?.data;
    return api.get(
        `${URL.GET_VAT_DETAILS_FOR_CLEINT}year=${year}`
    );
}

function approveProvisonalApi(data) {
    return api.update(URL.APPROVE_PROVISIONAL, data?.payload);
}

function rejectProvisonalApi(data) {
    return api.update(URL.REJECT_VAT, data?.payload);
}

export { getVatDetails, approveProvisonalApi, rejectProvisonalApi };
